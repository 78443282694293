const OLEDUI = {};

$(function () {
	// layout
	OLEDUI.layout = {
		main: $("main.main"),
		header: $("header.header"),
		footer: $("footer.footer"),
	};

	// page loading 시 무조건 상위로.
	// $(window).scrollTop(0);

	OLEDUI.SITE = $("html").attr("lang");
	if (OLEDUI.SITE === "ko") OLEDUI.SITE = "kr";
	if (OLEDUI.SITE === "zh") OLEDUI.SITE = "cn";
	if (OLEDUI.SITE === "ja") OLEDUI.SITE = "jp";
	if (OLEDUI.SITE === "de") OLEDUI.SITE = "de";

	// test
	OLEDUI.TEST = function () {
		const runTest = function () {
			$("#guideLayout").remove();
			$("body")
				.addClass("test")
				.append(
					'<div class="guide" id="guideLayout"><div class="max"></div><div class="layout"><div class="div div1"></div><div class="div div2"></div><div class="div div3"></div><div class="div div4"></div><div class="div div5"></div><div class="div div6"></div><div class="div div7"></div><div class="div div8"></div><div class="div div9"></div><div class="div div10"></div><div class="div div11"></div><div class="div div12"></div></div></div>'
				);
		};
		const addBtn = function () {
			$("body").append(
				'<div id="testBtn" style="position: fixed; left: 0; top: 27px; padding: 5px;z-index: 99999;"><button class="btn-test" style="font-size:12px;">TEST</button></div>'
			);
		};
		addBtn();
		$(".btn-test").on("click", function () {
			if ($("body > .guide").length > 0) {
				$("body > .guide").remove();
				$("body").removeClass("test");
			} else {
				runTest();
			}
		});
	};

	const url = document.location.pathname;
	if (url.indexOf("/components/") >= 0 && url.indexOf(".html") >= 0) OLEDUI.TEST();

	// Referrer
	const ref = window.location.search;
	if(ref.indexOf('?referrer=') !== -1) {
		history.pushState(null, null, window.location.protocol + "//" +  window.location.host + window.location.pathname);
	}


	/*! Scrolling Control  */
	OLEDUI.smoothscroll = {
		killChildTweensOf: function (parent, complete) {
			var parents = gsap.utils.toArray(parent),
				i = parents.length,
				_isDescendant = function (element) {
					while (element) {
						element = element.parentNode;
						if (element === parent) {
							return true;
						}
					}
				},
				j,
				tweens,
				targets;
			if (i > 1) {
				while (--i > -1) {
					OLEDUI.smoothscroll.killChildTweensOf(parents[i], complete);
				}
				return;
			}
			parent = parents[0];
			tweens = gsap.globalTimeline.getChildren(true, true, false);
			for (i = 0; i < tweens.length; i++) {
				targets = tweens[i].targets();
				j = targets.length;
				for (j = 0; j < targets.length; j++) {
					if (_isDescendant(targets[j])) {
						if (complete) {
							tweens[i].totalProgress(1);
						}
						tweens[i].kill();
					}
				}
			}
		},
		passive: function () {
			let supportsPassive = false;
			try {
				document.addEventListener("test", null, {
					get passive() {
						supportsPassive = true;
					},
				});
			} catch (e) {}
			return supportsPassive;
		},
		init: function () {
			if (window.devicePixelRatio > 1) return;
			const $window = $(window);
			if (this.passive()) {
				window.addEventListener("wheel", this.scrolling, { passive: false });
			} else {
				$window.on("mousewheel DOMMouseScroll", this.scrolling);
			}
		},
		destroy: function () {
			if (this.passive()) {
				window.removeEventListener("wheel", this.scrolling);
			} else {
				$(window).off("mousewheel DOMMouseScroll", this.scrolling);
			}
			OLEDUI.smoothscroll.killChildTweensOf($(window), { scrollTo: true });
		},
		scrolling: function (event) {
			if ($(event.target).hasClass("js-scroll") || $(event.target).closest(".js-scroll").length > 0) {
				return false;
			}
			event.preventDefault();
			//if (event.currentTarget === window) event.preventDefault();
			//const $window = $(window);
			const $scrollArea = $(event.currentTarget);
			const scrollTime = 1;
			const distance_offset = 2.5;
			const scrollDistance = $scrollArea.height() / distance_offset;
			let delta = 0;

			if (OLEDUI.smoothscroll.passive()) {
				delta = event.wheelDelta / 120 || -event.deltaY / 3;
			} else {
				if (typeof event.originalEvent.deltaY != "undefined") {
					delta = -event.originalEvent.deltaY / 120;
				} else {
					delta = event.originalEvent.wheelDelta / 120 || -event.originalEvent.detail / 3;
				}
			}

			const scrollTop = $scrollArea.scrollTop();
			const finalScroll = scrollTop - parseInt(delta * scrollDistance);

			gsap.to($scrollArea, scrollTime, {
				scrollTo: { y: finalScroll, autoKill: true },
				ease: Power3.easeOut,
				overwrite: 5,
			});
		},
	};
	// OLEDUI.smoothscroll.init();

	// div scroll for Firefox
	// $(".js-scroll").on("DOMMouseScroll", function (e) {
	// 	const st = $(this).scrollTop();
	// 	let h = $(this).height() * -1;
	// 	if (e.originalEvent.detail > 0) {
	// 		//scroll down
	// 		h = h * -1;
	// 	}
	// 	$(this)
	// 		.stop()
	// 		.animate({ scrollTop: st + h }, 0);

	// 	//prevent page fom scrolling
	// 	return false;
	// });
	// // div scroll for IE, Opera, Safari
	// $(".js-scroll").on("mousewheel", function (e) {
	// 	const st = $(this).scrollTop();
	// 	let h = $(this).height() * -1;
	// 	if (e.originalEvent.wheelDelta < 0) {
	// 		//scroll down
	// 		h = h * -1;
	// 	}
	// 	$(this)
	// 		.stop()
	// 		.animate({ scrollTop: st + h }, 0);
	// 	//prevent page fom scrolling
	// 	return false;
	// });

	// be in screen
	// t : target's offset().top
	// b : target's offset().top + height()
	OLEDUI.isInScreen = function (t, b) {
		const sTop = $(window).scrollTop();
		const bTop = $(window).scrollTop() + window.innerHeight;
		const inScreen = {
			complete: t >= sTop || b <= bTop,
			onlyTop: !(t > bTop || b < sTop),
			onlyBottom: t < sTop && b > bTop,
		};
		if ((inScreen.complete && inScreen.onlyTop) || inScreen.onlyBottom) {
			return true;
		} else {
			// console.groupEnd("check");
			return false;
		}
	};
	// 해당 영역이 화면의 중앙에 위치하는지 확인
	OLEDUI.isCenter = function (t, b) {
		const wHeight = window.innerHeight;
		const oHeight = b - t;
		const sTop = $(window).scrollTop();
		const bTop = $(window).scrollTop() + wHeight;
		const wTop = $(window).scrollTop() + wHeight / 2;
		const oTop = t + oHeight / 2;
		const isCenter = {
			complete: t >= sTop || b <= bTop,
			posCenter: wTop > oTop - wHeight / 4 && wTop < oTop + wHeight / 4,
		};
		if (isCenter.complete && isCenter.posCenter) {
			return true;
		} else {
			return false;
		}
	};

	// auto scrolled tab for mobile view
	OLEDUI.autoTabScroll = function () {
		$(".tab-box ul").each(function () {
			const tabScroll =
				$(this).parent().width() +
				parseFloat($(this).parent().css("padding-left")) +
				parseFloat($(this).parent().css("padding-right"));
			const activeTab = $(this).find(".is-active");
			const activeTabWidth =
				activeTab.width() +
				parseFloat(activeTab.css("padding-left")) +
				parseFloat(activeTab.css("padding-right"));

			$(this)
				.stop()
				.animate({ scrollLeft: activeTab[0].offsetLeft - (tabScroll - activeTabWidth) / 2 }, 900);
		});
	};
	if ($(".STOR").length === 0) {
		$(window).on("resize", function () {
			if (window.matchMedia("(max-width: 750px)").matches) {
				OLEDUI.autoTabScroll();
				OLEDUI.MobileScrollTab();
			}
		});
	}

	const $tab = $(".tab-box");
	const $tabBtnPrev = $tab.find(".tab-btn-prev");
	const $tabBtnNext = $tab.find(".tab-btn-next");

	// scroll arrow button click
	OLEDUI.TabBtnActive = function () {
		$tab.find(".btn").on("click", function () {
			const $this = $(this);
			if ($this.hasClass("tab-btn-prev")) {
				$tab.find(".is-active").prev().children()[0].click();
			} else if ($this.hasClass("tab-btn-next")) {
				$tab.find(".is-active").next().children()[0].click();
			}
		});
	};
	OLEDUI.TabBtnActive();

	// scroll tab-box button
	OLEDUI.MobileScrollTab = function () {
		const $tabScroll =
			$tab.find("ul").width() +
			parseFloat($tab.find("ul").css("padding-left")) +
			parseFloat($tab.find("ul").css("padding-right"));

		if ($tab.find("ul").scrollLeft() === 0) {
			$tabBtnPrev.removeClass("active");
			$tabBtnPrev.addClass("hidden");
			setTimeout(function () {
				$tabBtnPrev.css("display", "none");
			}, 100);
		} else if (parseInt($tab.find("ul").scrollLeft() + $tabScroll) >= $tab.find("ul").prop("scrollWidth") - 1) {
			// 400 이하에서 1px이 scrollLeft 가 -1 작게 잡혀서
			$tabBtnNext.removeClass("active");
			$tabBtnNext.addClass("hidden");
			setTimeout(function () {
				$tabBtnNext.css("display", "none");
			}, 100);
		} else {
			if ($tab.find(".btn").hasClass("hidden")) {
				$tab.find(".btn").css("display", "block");
				setTimeout(function () {
					$tab.find(".btn").removeClass("hidden");
					$tab.find(".btn").addClass("active");
				}, 100);
			}
		}
	};
	$(".tab-box ul").on("scroll", function () {
		OLEDUI.MobileScrollTab();
	});

	// get style value
	// _elem : target DOM.
	// _property : wanted style value.
	// _type : allowed datatype are "string" and "number". default is "number".
	OLEDUI.getStyleValue = function (_elem, _property, _type) {
		const request_type = _type || "number";
		const get_value = window.getComputedStyle(_elem, null).getPropertyValue(_property);
		const unit = /[px|vw|\%|em]/gi;
		// console.log("get_value :", get_value);
		switch (request_type) {
			case "number":
				return Number(get_value.replace(unit, ""));
			case "string":
				return get_value;
			default:
				throw new Error("There are not allowed datatype. Allowed datatype are only string and number");
				return null;
		}
	};

	// main's min-height
	OLEDUI.fixMainSize = function () {
		const run = function () {
			if (OLEDUI.layout["main"].length === 0) return false;
			const wh = $(window).height();
			const fh = $("footer.footer").height();
			const mainMinHeight = wh - fh;
			if (mainMinHeight > 0) {
				OLEDUI.layout["main"].css("min-height", mainMinHeight);
			} else {
				OLEDUI.layout["main"].removeAttr("style");
			}
		};
		$(window)
			.on("resize", function () {
				run();
			})
			.resize();
	};
	OLEDUI.fixMainSize();

	// Button hover (mobile)
	OLEDUI.btnHover = function () {
		$(document).on("touchstart", ".btn", function (e) {
			$(this).addClass("hover");
		});
		$(document).on("touchend", ".btn", function (e) {
			$(this).removeClass("hover");
		});
		$(document).on("click", function (e) {
			if (!$(this).hasClass("btn")) {
				$(".btn").removeClass("hover");
			}
		});
	};
	OLEDUI.btnHover();

	// image lazyload
	OLEDUI.Lazyload = function () {
		const changeImgSrc = function ($img, src) {
			if (src.indexOf(",") > -1) {
				if (window.matchMedia("(max-width: 750px)").matches) {
					$img.attr("src", src.split(",")[0].trim());
				} else {
					$img.attr("src", src.split(",")[1].trim());
				}
			}
		};
		let lazyloadImages;
		if ("IntersectionObserver" in window) {
			lazyloadImages = document.querySelectorAll(".lazy");
			const imageObserver = new IntersectionObserver(function (entries, observer) {
				// console.log(observer);
				entries.forEach(function (entry) {
					if (entry.isIntersecting) {
						const image = entry.target;
						if (image.dataset.src.indexOf(",") === -1) {
							image.src = image.dataset.src;
						} else {
							changeImgSrc($(image), image.dataset.src);
						}
						image.classList.remove("lazy");
						image.classList.add("loaded");
						imageObserver.unobserve(image);
					}
				});
			});

			lazyloadImages.forEach(function (image) {
				imageObserver.observe(image);
			});
			// } else {
			// 	let lazyloadThrottleTimeout;
			// 	lazyloadImages = $(".lazy");

			// 	function lazyload() {
			// 		if (lazyloadThrottleTimeout) {
			// 			clearTimeout(lazyloadThrottleTimeout);
			// 		}
			// 		lazyloadThrottleTimeout = setTimeout(function () {
			// 			const scrollTop = $(window).scrollTop();
			// 			lazyloadImages.each(function () {
			// 				const el = $(this);
			// 				if (el.offset().top < window.innerHeight + scrollTop + 500) {
			// 					const url = el.attr("data-src"); /* !! */
			// 					if (url.indexOf(",") === -1) {
			// 						el.attr("src", url);
			// 					} else {
			// 						changeImgSrc(el, url);
			// 					}
			// 					el.removeClass("lazy").addClass("loaded");
			// 					lazyloadImages = $(".lazy");
			// 				}
			// 			});
			// 			if (lazyloadImages.length == 0) {
			// 				$(document).off("scroll.lazy");
			// 				$(window).off("resize.lazy");
			// 			}
			// 		}, 20);
			// 	}

			// 	$(document).on("scroll.lazy", lazyload).scroll();
			// 	$(window).on("resize.lazy", lazyload).resize();
		}
		window.matchMedia("(max-width: 750px)").addListener(function (event) {
			const $imgs = $("img.loaded");
			$imgs.each(function () {
				const $img = $(this);
				const src = $img.data("src");
				changeImgSrc($img, src);
			});
		});
	};
	OLEDUI.Lazyload();

	// recognize scroll direction
	OLEDUI.getScrollDirection = function (_from, _to) {
		if (_from > _to) return -1; // up scroll
		return 1; // down scroll
	};

	// hero-keyvisual
	OLEDUI.heroKeyvisualAni = function () {
		const $hero = $("#hero");
		const $bg = $hero.find(".image-area").find("video, picture");
		const $txt = $hero.find(".floating-area .text-box");
		const $dim = $hero.find(".image-area .dimmed");
		const $cookie = $(".cookie-area");
		let heroHeight = $hero.outerHeight();
		let cookieHeight = $cookie.outerHeight() || 0;
		const runResize = function () {
			heroHeight = $hero.outerHeight();
			cookieHeight = $cookie.outerHeight() || 0;
			let maxHeight = parseInt($(window).height() - cookieHeight);
			if ($cookie.length === 0 || $cookie.css("display") == "none") maxHeight = $(window).height();
			if ($(window).width() <= 750) {
				// mobile view
				let maxDesignHeight = 0;
				const ww = $(window).width();
				if ($hero.closest(".oled-inside-detail").length > 0) {
					// maxDesignHeight = parseInt(450*ww/375);
					maxDesignHeight = parseInt((660 * ww) / 375); // 22.08.05 products와 동일하게 변경됨.
				} else if ($hero.closest(".products").length > 0) {
					maxDesignHeight = parseInt((660 * ww) / 375);
				} else {
					maxDesignHeight = parseInt((460 * ww) / 375);
				}
				if (maxHeight > maxDesignHeight) maxHeight = maxDesignHeight;
			} else {
				// desktop view
				let maxDesignHeight = 0;
				const ww = $(window).width();
				if ($hero.closest(".oled-inside-detail").length > 0) {
					maxDesignHeight = parseInt((940 * ww) / 1920);
				} else if ($hero.closest(".products").length > 0) {
					maxDesignHeight = parseInt((880 * ww) / 1920);
				} else {
					maxDesignHeight = parseInt((700 * ww) / 1920);
				}
				if (maxHeight > maxDesignHeight) maxHeight = maxDesignHeight;
			}
			$hero.css("max-height", maxHeight);
		};
		const runScroll = function () {
			const sc = $(window).scrollTop();
			if (sc < heroHeight) {
				$bg.css("margin-top", sc / 3);
				$txt.css("margin-bottom", sc / 15);
				let opq = sc / $(window).height();
				if (opq > 1) opq = 1;
				$dim.css("opacity", opq);
			}
		};
		$(window).on("scroll", runScroll).scroll();
		$(window).on("resize", runResize).resize();

		$hero.find(".cta-wrap button[data-id=keyvisualVideo]").on("click", function () {
			pushDataLayer({
				event: "click_video",
				click_video: {
					videoTitle: convertTitle($(this).closest(".floating-text").find(".title").text() + " kv"),
					videoProvider: "oledspace",
				},
			});
		});

		// const $titleBox = $(".title-box");
		// const $imgBox = $(".image-box");
		// const $floatingArea = $(".floating-area");
		// if ($titleBox.length === 0 && $imgBox.length === 0) return false;
		// const searchTxt = "?animate=false";
		// if (window.location.search === searchTxt || $(window).width() <= 750) {
		// 	$imgBox.addClass("active");
		// 	$titleBox.addClass("active");
		// 	$titleBox.css("height", parseInt($titleBox.css("min-height")));
		// 	if ($(window).width() > 750) $imgBox.css("transform", "translateY(-10%)");
		// 	// OLEDUI.layout.header.find(".gnb-area").addClass("border");
		// 	// OLEDUI.layout.header.find(".breadcrumb").addClass("active");
		// 	const url = window.location.href.replace(searchTxt, "");
		// 	history.pushState(null, null, url);
		// 	return false;
		// }
		// let wh = $(window).height();
		// let ih = Math.min($imgBox.height(), wh);
		// $("body").addClass("fixed");
		// // const signElem = document.querySelector(".scroll-down");
		// // signElem.classList.add("is-show");

		// // setTimeout(function () {
		// // 	const st = $(window).scrollTop();
		// // 	if (st == 1) {
		// // 		$(window).scrollTop(0);
		// // 	}
		// // }, 1200);

		// // step0. set default height
		// $titleBox.css("height", ih + 1);

		// // step1. show dimmed area
		// $imgBox
		// 	.addClass("active")
		// 	.promise()
		// 	.done(function () {
		// 		//step2. show text box
		// 		// setTimeout(function () {
		// 		$titleBox.addClass("active");
		// 		//$(window).on("scroll.titlebox", runScroll).scroll();
		// 		setTimeout(function () {
		// 			$(window).on("scroll.titlebox touchmove.titlebox mousewheel.titlebox", runScroll);
		// 		}, 500);
		// 		// }, 900);
		// 	});

		// let finished = false;
		// const resizeScroll = function () {
		// 	if (!finished) {
		// 		if ($(window).width() <= 750) {
		// 			$titleBox.removeAttr("style");
		// 			$imgBox.removeAttr("style");
		// 		} else {
		// 			wh = $(window).height();
		// 			ih = Math.min($imgBox.height(), wh);
		// 			$titleBox.css("height", ih);

		// 			if ($titleBox.parent().hasClass("about-oled")) {
		// 				// $titleBox.css("height", "32.6241134752vw");
		// 				// console.log(wh, $imgBox.height());
		// 				if ($floatingArea.hasClass("floating")) {
		// 					console.log("float!");
		// 				} else {
		// 					$titleBox.css("height", ih);
		// 				}
		// 			} else {
		// 				$titleBox.css("height", ih);
		// 			}
		// 		}
		// 	} else {
		// 		if ($titleBox.parent().hasClass("about-oled") && $floatingArea.hasClass("floating")) {
		// 			// const signElem = document.querySelector(".scroll-down");
		// 			$titleBox.removeAttr("style");
		// 			// signElem.classList.remove("is-show");
		// 			if ($(window).width() <= 750) $imgBox.removeAttr("style");
		// 		}
		// 	}
		// 	// console.log($imgBox.height());
		// };
		// const runScroll = function (event) {
		// 	const st = $(window).scrollTop();
		// 	ih = Math.min($imgBox.height(), wh);
		// 	const cssMinHeight = parseInt($titleBox.css("min-height"));
		// 	// const signElem = document.querySelector(".scroll-down");
		// 	if (!finished && st > 0) {
		// 		$titleBox.css("height", cssMinHeight);
		// 		if ($(window).width() > 750) $imgBox.css("transform", "translateY(-10%)");
		// 		// OLEDUI.layout.header.find(".gnb-area").addClass("border");
		// 		if (st == 1) $(window).scrollTop(0);
		// 		// signElem.classList.remove("is-show");
		// 		// signElem.setAttribute("aria-hidden", true);
		// 		// Case About OLED
		// 		if ($titleBox.parent().hasClass("about-oled")) $floatingArea.addClass("floating");
		// 		setTimeout(function () {
		// 			// OLEDUI.layout.header.find(".breadcrumb").addClass("active");
		// 			$(window).off("scroll.titlebox touchmove.titlebox mousewheel.titlebox", runScroll);
		// 			$("body").removeClass("fixed");
		// 			finished = true;
		// 			OLEDUI.runAnimate();
		// 		}, 200);
		// 	} else if (!finished) {
		// 		if (st == 1) $(window).scrollTop(0);
		// 	}
		// };
		// $(window).on("resize", resizeScroll).resize();
	};
	OLEDUI.heroKeyvisualAni();

	//user country check
	// OLEDUI.UserCountry = function (callback) {
	// 	// https://geoip.cookieyes.com/geoip/checker/result.php
	// 	$.getJSON("https://freegeoip.app/json/", function (data) {
	// 		callback(data.country_code);
	// 	});
	// };

	// filter ie 11
	OLEDUI.useModernPlatform = (function () {
		const filterCase = [
			{ key: document.scrollingElement }, // HTML
			{ key: window.scrollY }, // scroll top
			{ key: !navigator.appMinorVersion }, // navigator property
		];
		const checker = filterCase.filter((check) => check["key"] !== undefined && check["key"] !== false);
		if (filterCase.length === checker.length) return true;
		return false;
	})();

	// parallax scroll animation :: Get time to "data-delay" property on html
	// OLEDUI.watchTimer = function ($el) {
	// 	const obj = $el;
	// 	if (obj.attr("data-delay") && !obj.hasClass("hidden")) {
	// 		const delayTimer = parseInt(obj.attr("data-delay"));
	// 		obj.delay(delayTimer).queue(function () {
	// 			obj.addClass("active").dequeue();
	// 		});
	// 		if (obj.hasClass("video-block") && obj.find(".lazy-youtube").length > 0) {
	// 			obj.delay(delayTimer).queue(function () {
	// 				// Lazyload Youtube Video
	// 				let $video = obj.find(".lazy-youtube");
	// 				let $src = $video.attr("data-src");
	// 				let $tit = $video.attr("data-tit");
	// 				//console.log("$video :", $video, $src, $tit);
	// 				$video
	// 					.html(
	// 						'<iframe src="' +
	// 							$src +
	// 							'"title="' +
	// 							$tit +
	// 							'" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
	// 					)
	// 					.dequeue();
	// 			});
	// 		}
	// 	}
	// };
	// animate instance constructor
	OLEDUI.axisAnimation = function (_options) {
		this.min = _options.min;
		this.max = _options.max;
		this.timeline_length = _options.timeline_length;
		this.target = _options.target;
		this.animate_property = _options.property;
		this.reverse_action = _options.reverse_action || false;
		const p = OLEDUI.axisAnimation.prototype;
		// prototype field
		p.roll = function (_distance) {
			this.progress = _distance / this.timeline_length;
			if (1 <= this.progress) this.progress = 1;
			if (0 >= this.progress) this.progress = 0;

			const v = this.progress * (this.max - this.min);
			const re_v = this.reverse_action ? this.min + v : this.max - v;
			// console.log(v, re_v);
			this.render(re_v);
			return re_v;
		};
		p.render = function (_value) {
			this.target.style[this.animate_property] = _value;
		};
	};

	// How to use
	// 1. add animate-box class
	// 2. add data-delay attribute
	OLEDUI.runAnimate = function (selector = ".video-block") {
		if (0 >= document.querySelectorAll(selector).length) return false;

		const $item = $(selector);
		function animateBox() {
			$item.each(function () {
				const $this = $(this);
				const t = $this.offset().top;
				const b = $this.offset().top + $this.height();
				if (OLEDUI.isInScreen(t, b)) {
					// OLEDUI.watchTimer($this);
					if ($this.find(".lazy-youtube").length > 0) {
						$this.queue(function () {
							// Lazyload Youtube Video
							let $video = $this.find(".lazy-youtube");
							let $src = $video.attr("data-src");
							let $tit = $video.attr("data-tit");
							//console.log("$video :", $video, $src, $tit);
							$video
								.html(
									'<iframe src="' +
										$src +
										'"title="' +
										$tit +
										'" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
								)
								.dequeue();
						});
					}
				}
			});
		}
		animateBox();
		$(window)
			.off("scroll.animate")
			.on("scroll.animate", function () {
				animateBox();
			});
		$item.find("a, button").on("focus", function () {
			const box = $(this).closest(".animate-box");
			if (!box.hasClass("active")) box.addClass("active");
		});
	};
	// if ($(".HOME").length === 0) OLEDUI.runAnimate();
	OLEDUI.runAnimate();

	// scroll down sign
	// OLEDUI.scrollSign = (function () {
	// 	// if (!document.querySelector(".scroll-down")) return false;
	// 	// const signElem = document.querySelector(".scroll-down");
	// 	let flag = null;
	// 	// signElem.classList.add("is-show");
	// 	const handleEvent = function (event) {
	// 		const scrollBottom = window.innerHeight + (window.scrollY || window.pageYOffset);
	// 		const commonHeader = document.querySelector("header.header");
	// 		// const signElem = document.querySelector(".scroll-down");
	// 		// show n hide
	// 		flag = commonHeader.classList.contains("scrolled");

	// 		if (flag) {
	// 			// signElem.classList.remove("is-show");
	// 			// signElem.setAttribute("aria-hidden", true);
	// 			return false;
	// 		}
	// 		// signElem.classList.add("is-show");
	// 		// signElem.setAttribute("aria-hidden", false);
	// 		// positioning
	// 		const firstScreenView = [".hero", ".collaborations.list > .image-box", ".oled-inside-list > .image-box"];
	// 		const checkElem = document.querySelectorAll(firstScreenView.join(", "));
	// 		if (0 >= checkElem.length) return false;
	// 		const checkLine = $(checkElem).offset().top + $(checkElem).height();
	// 		const scanLine = scrollBottom;
	// 		const isClip = scanLine < checkLine;
	// 		if (!isClip) {
	// 			// signElem.style.position = "absolute";
	// 			return false;
	// 		}
	// 		// signElem.style.position = "fixed";
	// 	};
	// 	handleEvent();
	// 	window.addEventListener("scroll", handleEvent, false);
	// 	window.addEventListener("resize", handleEvent, false);
	// 	return flag;
	// })();

	// jquery replace method
	// 1. index()
	OLEDUI.getIndex = (_this) => Array.prototype.indexOf.call(_this.parentElement.children, _this);
	// 2. $()
	OLEDUI.getElements = (_selector, _root = document) =>
		Array.prototype.map.call(_root.querySelectorAll(_selector), (el) => el);

	// Counting Number
	OLEDUI.countingNumber = function (el, _duration) {
		let duration = _duration || 900;
		const $el = $(el),
			countTo = $el.attr("data-count"),
			pointLength = $el.data("point-length"),
			initialCount = $el.text();

		$el.addClass("running");

		$({ countNum: initialCount }).animate(
			{
				countNum: countTo,
			},
			{
				duration: duration,
				easing: "linear",
				step: function () {
					$el.text(Number(this.countNum).toFixed(pointLength));
				},
				complete: function () {
					$el.text(countTo).removeClass("running");
				},
			}
		);
	};

	// Compare images
	OLEDUI.compareImages = function ($el) {
		const handle = $el.find(".handle");
		if (handle.length == 0) return false;

		let currentPercent = 0;
		const calculateWidth = function (event, ui) {
			console.log(ui);
			const fullWidth = parseInt($el.width());
			const currentLeft = parseInt(handle.css("left"));
			const percent = (currentLeft * 100) / fullWidth;
			currentPercent = percent;
			$el.find(".img-cover").css("width", currentLeft);
		};
		handle.draggable({
			containment: "parent",
			handle: "button",
			axis: "x",
			cancel: "a",
			drag: calculateWidth,
			stop: calculateWidth,
		});
		handle.find("button").on("keydown", function () {
			var keyCode = event.keyCode;
			var fullWidth = parseInt($el.width());
			var currentLeft = parseInt(handle.css("left"));
			var percent = (currentLeft * 100) / fullWidth;
			if (keyCode == 37) {
				// left
				percent = percent - 10;
			} else if (keyCode == 39) {
				// right
				percent = percent + 10;
			}
			if (percent < 0) percent = 0;
			if (percent > 100) percent = 100;
			currentPercent = percent;
			currentLeft = (fullWidth * currentPercent) / 100;
			if (currentLeft === fullWidth) currentLeft = fullWidth - 1; // show line at the right end
			handle.css("left", currentLeft);
			$el.find(".img-cover").css("width", currentLeft);
		});
		$(window).resize(function () {
			if (currentPercent === 0) currentPercent = 50;
			handle.css("left", currentPercent + "%");
			$el.find(".img-cover").css("width", currentPercent + "%");
		});
	};

	// Store Banner
	OLEDUI.storeBanner = function () {
		const $bannerLink = $(".store-banner .btn-area a");
		$bannerLink.on("click", function () {
			pushDataLayer({
				event: "click_button",
				click_button: {
					buttonText: convertTitle($(this).text()),
					sectionName: "oled-store-banner",
					sectionContentTitle: "",
					sectionContentSub: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
	};
	OLEDUI.storeBanner();

	// Store Banner
	OLEDUI.productsBanner = function () {
		const $bannerLink = $(".products-banner .products-item a");
		$bannerLink.on("click", function () {
			pushDataLayer({
				event: "click_product",
				click_product: {
					productTitle: convertTitle($(this).find(".title").text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
	};
	OLEDUI.productsBanner();

	// recommended article (for article detail)
	let titleType1 = convertTitle($(".section-title-box .text-box .title").text());
	let titleType2 = convertTitle($(".hero-keyvisual .floating-area .floating-text .title").text());
	OLEDUI.INSDTitle = !!titleType1 ? titleType1 : titleType2;
	// exception
	if ($("body").hasClass("INSD2020027")) {
		// /oled-inside/experience/open-innovation-forum-unlock-your-future-with-oled/
		OLEDUI.INSDTitle = convertTitle($(".collaborations.oif .hero .text-box .title .head").text());
	} else if ($("body").hasClass("INSD2021009")) {
		// /oled-inside/tech-review/oled-as-your-choice-to-protect-ocular-health/
		OLEDUI.INSDTitle = convertTitle($(".collaborations.eyesafe .hero .text-box .title img").attr("alt"));
	} else if ($("body").hasClass("INSD2020016")) {
		// /oled-inside/experience/an-engagement-of-two-luxuries-harrods-meets-oled/
		OLEDUI.INSDTitle = convertTitle($(".collaborations.harrods .hero .text-box .eyebrow img").attr("alt"));
	} else if ($("body").hasClass("INSD2021029")) {
		// /oled-inside/experience/nothing-feels-better-oled-bliss/
		OLEDUI.INSDTitle = convertTitle($(".kv-title-img img").attr("alt"));
	}
	// - exception
	if (typeof INSDCategory == "string") {
		OLEDUI.recommendedArticle = function () {
			const $bannerLink = $(".recommended-article a");
			$bannerLink.on("click", function () {
				pushDataLayer({
					event: "click_article",
					click_article: {
						articleTitle: OLEDUI.INSDTitle,
						clickSection: convertTitle($(".recommended-article .text-box .title").text()),
						articleCategory: INSDCategory,
						clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
					},
				});
			});
		};
		OLEDUI.recommendedArticle();
	}

	// page load (for article detail)
	if (typeof INSDCategory == "string" && typeof OLEDUI.INSDTitle == "string") {
		pushDataLayer({
			event: "view_article",
			view_article: {
				articleTitle: OLEDUI.INSDTitle,
				articleCategory: INSDCategory,
			},
		});
	}

	// for case of open layer popup - such as GNB, search window, modal...
	OLEDUI.bandPageScroll = function () {
		const wrapper = document.querySelector(".wrapper");
		// console.log(window.scrollY, `${window.scrollY * -1}px`);

		// position 값이 fixed보다 먼저 와야 함.
		wrapper.style.top = `${window.scrollY * -1}px`;
		wrapper.style.left = 0;
		wrapper.style.right = 0;
		wrapper.style.position = "fixed";
		wrapper.style.zIndex = 0;
	};

	OLEDUI.removeStyle = function () {
		const wrapper = document.querySelector(".wrapper");
		const memorize_position = parseInt(wrapper.style.top.replace("px", ""));
		wrapper.removeAttribute("style");
		window.scroll(0, memorize_position * -1);
	};
});
